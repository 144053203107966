import React, { useRef, useState } from 'react'
import Header from '../layout/header'
import html2pdf from 'html2pdf.js'
import Chart from 'react-apexcharts'
import { useQuery } from '@apollo/client'
import { GET_TOP_REPORT } from '../graphql/query'

export default function TopLevel() {
    const date = new Date()
    const year = date?.getFullYear();

    const [months, setMonths] = useState(`${date?.toLocaleString('default', { month: 'long' })}-${year}`)
    const refs = useRef(null)

    const onPrint = async () => {
        if (refs?.current) {
            await html2pdf(refs?.current, { image: { type: 'jpeg', quality: 1 }, filename: `Top Level Report.pdf`, jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }, pagebreak: { mode: ['avoid-all', 'css', 'legacy'] } });
        }
    }

    const { data, loading } = useQuery(GET_TOP_REPORT, {
        variables: {
            months: monthNames
        }
    })

    const selectedMonth = monthNames?.findIndex((e) => e === months)
    
    function getMonthByIndex(index) {
        const adjustedIndex = (index % monthNames.length + monthNames.length) % monthNames.length;
        return index >= 0? monthNames[adjustedIndex] : ""
    }
    
    const oldMonths = [getMonthByIndex(selectedMonth - 3), getMonthByIndex(selectedMonth - 2), getMonthByIndex(selectedMonth - 1)]

    const getObjective = () => {
        let above = 0
        let on = 0
        let below = 0
        allReport?.forEach((itm)=>{
            const datas = data?.getMonthReports?.filter((e) => e?.role === itm?.role)
            const target = data?.getMonthTargets?.find((e) => e?.role === itm?.role && e?.month === oldMonths[2])
            const prevTarget = data?.getMonthTargets?.find((e) => e?.role === itm?.role && e?.month === oldMonths[1])
            const month = target?.targets ? JSON.parse(target?.targets) : {}
            const prevMonth = prevTarget?.targets? JSON.parse(prevTarget?.targets) : {}
            const data2 = datas?.find((e) => e?.month === oldMonths[2])?.reports ? JSON.parse(datas?.find((e) => e?.month === oldMonths[2])?.reports) : {}
            if(parseInt(month[itm?.id] || 0) - parseInt(prevMonth[itm?.id] || 0) === (parseInt(data2[itm?.id] || 0))){
                on+=1
            }else if(parseInt(month[itm?.id] || 0) - parseInt(prevMonth[itm?.id] || 0) < (parseInt(data2[itm?.id] || 0))){
                above+=1
            }else{
                below+=1
            }
        })
        return {
            above: above,
            on: on,
            below: below
        }
    }

    return (
        <div className='Dashboard'>
            <Header title={"Board Report"} month={months} setMonth={setMonths} btn={<button onClick={() => onPrint()}>Download</button>} />
            <span className='Dashboard_Con' ref={refs}>
                {loading ?
                    <div style={{ minHeight: "calc(100vh - 100px)" }} className="Loading">
                        Loading...
                    </div>
                    :
                    <>
                        {reports?.map((item, index) => {
                            return (
                                <div key={index}>
                                    <h3>{item?.name}</h3>
                                    <div className='Dashboard_Grid_Con'>
                                        {item?.items?.map((itm, ind) => {
                                            const datas = data?.getMonthReports?.filter((e) => e?.role === itm?.role)
                                            const targets = data?.getMonthTargets?.find((e) => e?.role === itm?.role && e?.month === oldMonths[2])
                                            const prevTarget = data?.getMonthTargets?.find((e) => e?.role === itm?.role && e?.month === oldMonths[1])
                                            const yearTarget = data?.getTargets?.find((e) => e?.role === itm?.role)
                                            return <Report key={ind} datas={datas} target={targets} prevTarget={prevTarget} yearTarget={yearTarget} item={itm} oldMonths={oldMonths} title={itm?.title} selectedMonth={selectedMonth} months={months}/>
                                        })}
                                    </div>
                                </div>
                            )
                        })}
                        <div className='Dashboard_Grid_Con1'>
                            <div className='Dashboard_Grid_Item1'>
                                <h4>No. of objectives above target</h4>
                                <p>{getObjective()?.above || "0"}</p>
                            </div>
                            <div className='Dashboard_Grid_Item1'>
                                <h4>No. of objectives on target</h4>
                                <p>{getObjective()?.on || "0"}</p>
                            </div>
                            <div className='Dashboard_Grid_Item1'>
                                <h4>No. of objectives below target</h4>
                                <p>{getObjective()?.below || "0"}</p>
                            </div>
                        </div>
                    </>
                }
            </span>
        </div>
    )
}

function Report({ oldMonths, title, target, yearTarget, item, prevTarget, datas, selectedMonth }) {
    const year = yearTarget?.targets ? JSON?.parse(yearTarget?.targets) : {}
    const month = target?.targets ? JSON.parse(target?.targets) : {}
    const prevMonth = prevTarget?.targets? JSON.parse(prevTarget?.targets) : {}
    
    const thisMonth = monthNames?.filter((itm, ind)=> ind <= selectedMonth - 1)
    const lastMonth = monthNames?.filter((itm, ind)=> ind <= selectedMonth - 2)
    const preMonth = monthNames?.filter((itm, ind)=> ind <= selectedMonth - 3)
    const data2 = datas?.find((e) => e?.month === oldMonths[2])?.reports ? JSON.parse(datas?.find((e) => e?.month === oldMonths[2])?.reports) : {}

    var options = {
        chart: {
            type: 'bar',
            xaxis: {
                categories: oldMonths?.map((item) => {
                    return item?.slice(0, 3) + '-' + item?.slice(-2)
                })
            },
            chart: {
                toolbar: {
                    show: false
                }
            },
            stroke: {
                width: 2
            },
            tooltip: {
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                    return (
                        `<div class="ToolTip">
                      ${w.globals.labels[dataPointIndex]}: ${series[seriesIndex][dataPointIndex]}
                      </div>`
                    )
                }
            },
        },
        series: [{
            name: 'sales',
            data: oldMonths?.map((itms, inds)=>{
                if(inds === 2){
                    let val = 0
                    thisMonth?.forEach((itm)=>{
                        const data = datas?.find((e) => e?.month === itm)?.reports ? JSON.parse(datas?.find((e) => e?.month === itm)?.reports) : {}
                        const monthVal = parseFloat(data[item?.id]) || 0
                        val += monthVal
                    })
                    return val
                } else if(inds === 1){
                    let val = 0
                    lastMonth?.forEach((itm)=>{
                        const data = datas?.find((e) => e?.month === itm)?.reports ? JSON.parse(datas?.find((e) => e?.month === itm)?.reports) : {}
                        const monthVal = parseFloat(data[item?.id]) || 0
                        val += monthVal
                    })
                    return val
                } else{
                    let val = 0
                    preMonth?.forEach((itm)=>{
                        const data = datas?.find((e) => e?.month === itm)?.reports ? JSON.parse(datas?.find((e) => e?.month === itm)?.reports) : {}
                        const monthVal = parseFloat(data[item?.id]) || 0
                        val += monthVal
                    })
                    return val
                }
            })
        }],
    }

    return (
        <div className='Dashboard_Grid_Item'>
            <div className='Dashboard_Grid_Left'>
                <h4>{title}</h4>
                <div className='FlexCon' />
                <p>Month: {oldMonths[2]}</p>
                {month[item?.id] ? <p>Monthly Target: {parseInt(month[item?.id] || 0) - parseInt(prevMonth[item?.id] || 0)}</p> : null}
                {year[item?.id]? <p>Monthly Cumulative Target: {month[item?.id] || '--'}</p> : null}
                {year[item?.id] ? <p>Yearly Target: {year[item?.id] || '--'}</p> : null}
                {year[item?.id] ? <p style={{ marginBottom: 40 }} onClick={()=>console.log(data2)}>Achieved: {parseInt(data2[item?.id] || 0) || '0'}</p>: null}
                <div className='FlexCon' />
                {month[item?.id] ?
                <div className={parseInt(month[item?.id] || 0) - parseInt(prevMonth[item?.id] || 0) === (parseInt(data2[item?.id] || 0))? 'Dashboard_Grid_Ind': parseInt(month[item?.id] || 0) - parseInt(prevMonth[item?.id] || 0) < (parseInt(data2[item?.id] || 0))? "Dashboard_Grid_Ind Dashboard_Grid_Ind_Green" : "Dashboard_Grid_Ind Dashboard_Grid_Ind_Red"}/>
                : null}
            </div>
            <div className='Dashboard_Grid_Right'>
                <Chart height={"230"} options={options?.chart} series={options?.series} type='bar' />
            </div>
        </div>
    )
}

const reports = [
    {
        name: "FINANCE",
        items: [
            { title: "Income", id: "incomeLast", role: "Helpful" },
            { title: "Expenditure", id: "expenseLast", role: "Helpful" },
        ]
    },
    {
        name: "PRAYER",
        items: [
            { title: "Number of prayer partners", id: "noPrayerPartner", role: "Spiritual" },
        ]
    },
    {
        name: "10,000 CHURCHES CONTACTED",
        items: [
            { title: "Number of WYPM packs sent out", id: "noSeriesSent", role: "Together" },
            // { title: "Number of churches contacted", id: "noChurchEnquiry", role: "Relational" },
            { title: "No. of Church Consultations", id: "noChurchVisit", role: "Relational" },
            { title: "Number of advocacy social media responses", id: "noSocialResponse", role: "Courageous" },
            { title: "Number of Denominational Relationships", id: "noChurchEngage", role: "Courageous" },
        ]
    },
    {
        name: "5,000 RE TEACHERS",
        items: [
            { title: "Number of RE teachers subscribing", id: "noRequestReg", role: "Innovation" },
            { title: "Number of my life licences", id: "noCourseSale", role: "Together" },
        ]
    },
    {
        name: "1500 PARTNER CHURCHES",
        items: [
            { title: "Number of resource subscribers", id: "noNewSubscriber", role: "Together" },
            { title: "No. of new partners", id: "noNewPartners", role: "Together" }
        ]
    },
    {
        name: "100 COMMUNITIES",
        items: [
            { title: "Number of Pulse Gatherings", id: "noPluseGather", role: "Relational" },
            { title: "Number of learning communities", id: "noPluseLearn", role: "Relational" },
        ]
    },
    {
        name: "80 CENTRES",
        items: [
            { title: "Number of New Centres", id: "noCentreNew", role: "Relational" },
            { title: "Number of Centre Research launched", id: "researchLaunch", role: "Spiritual" },
        ]
    },
    {
        name: "1 NATIONAL",
        items: [
            { title: "Number of Urban Outreach events", id: "noUrbanEvent", role: "Relational" },
            { title: "Number of Young people reached (direct not including Centres)", id: "noYoungReach", role: "Courageous" },
            { title: "No. of Young People who made a decision to follow Jesus", id: "noYoungFollow", role: "Courageous" }
        ]
    },
]

const allReport = [
    { title: "Number of prayer partners", id: "noPrayerPartner", role: "Spiritual" },
    { title: "Number of WYPM packs sent out", id: "noSeriesSent", role: "Together" },
    { title: "Number of advocacy social media responses", id: "noSocialResponse", role: "Courageous" },
    { title: "Number of Denominational Relationships", id: "noChurchEngage", role: "Courageous" },
    { title: "Number of RE teachers subscribing", id: "noRequestReg", role: "Innovation" },
    { title: "Number of my life licences", id: "noCourseSale", role: "Together" },
    { title: "Number of resource subscribers", id: "noNewSubscriber", role: "Together" },
    { title: "Number of Pulse Gatherings", id: "noPluseGather", role: "Relational" },
    { title: "Number of learning communities", id: "noPluseLearn", role: "Relational" },
    { title: "Number of New Centres", id: "noCentreNew", role: "Relational" },
    { title: "Number of Urban Outreach events", id: "noUrbanEvent", role: "Relational" },
    { title: "No. of new partners", id: "noNewPartners", role: "Together" }
]

const monthNames = ['September-2024', 'October-2024', 'November-2024', 'December-2024', 'January-2025', 'February-2025', 'March-2025', 'April-2025', 'May-2025', 'June-2025', 'July-2025', 'August-2025', 'September-2025'];